import { FC, useContext, useEffect, useMemo, useState } from 'react'
import {
	Row,
	Col,
	Card,
	Badge,
	Table,
	ProgressBar,
	Form,
	Button
} from 'react-bootstrap'
import Flex from 'components/common/Flex'
import {
	BsFillPersonFill,
	BsFillBriefcaseFill,
	BsFillEnvelopeFill,
	BsFillChatDotsFill,
	BsFillFileEarmarkTextFill
} from 'react-icons/bs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CountUp from 'react-countup'
import { FaBuilding } from 'react-icons/fa'
import FalconCardHeader from 'components/common/FalconCardHeader'
import CardDropdown from 'components/common/CardDropdown'
import AppContext from 'context/Context'
import React from 'react'
import SimpleBarReact from 'simplebar-react'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { getColor, rgbaColor } from 'helpers/utils'
import * as echarts from 'echarts/core'
import {
	GridComponent,
	LegendComponent,
	TitleComponent,
	TooltipComponent
} from 'echarts/components'
import Select from 'react-select'
import { BiRefresh } from 'react-icons/bi'
import useAuth from 'hooks/useAuth'
import { IFormData } from 'services/vittamais-core/dashboard/interfaces'
import { search as searchGeneralDashboard } from 'services/vittamais-core/dashboard/dashboard'
import useSweetAlert from 'context/Alerts/SweetAlert'
import { IDashboardData } from './inteface'
import CardsInfo from './cardsInfo'
import TableDashboard from './tableDashboard'
import ChartDashboard from './chartDashboard'
import ProgressBarDashboard from './progressBar'
import CustomLoading from 'components/common/CustomLoading'

const Dashboard: FC = function Dashboard() {
	const {
		config: { isDark, isRTL },
		setConfig
	} = useContext(AppContext)

	const customStyles = {
		control: (provided: any, state: any) => {
			const isDisabled = state.selectProps.isDisabled

			let color = isDark ? 'white' : ''
			let backgroundColor = isDark ? '#0b1727' : ''
			let borderColor = isDark ? '#344050' : '#dae3f0'

			if (isDisabled && !isDark) {
				backgroundColor = '#edf2f9'
				color = '#4a4f5e'
				borderColor = '#dce5f1'
			}

			return {
				...provided,
				backgroundColor,
				color,
				borderColor
			}
		}
	}

	const { dataAuth } = useAuth()
	const [CustomSelect, setCustomSelect] = useState(false)
	const [loading, setLoading] = useState(false)
	const [changeLabel ,setChangeLabel] = useState(false)
	const { showAlert } = useSweetAlert()
	const [dashboardControl, setDashboardControl] =
		useState<IDashboardData | null>(null)

	const today = new Date().toLocaleDateString('pt-BR')
	const todayOnlyDate =
		today.substr(6, 4) + '-' + today.substr(3, 2) + '-' + today.substr(0, 2)

	const [formData, setFormData] = useState<IFormData>({
		period: 'hoje',
		final_date: todayOnlyDate,
		initial_date: todayOnlyDate,
		pdv_id: [
			{
				label: dataAuth?.origin_scope?.pdv_origin?.name,
				value: dataAuth?.origin_scope?.pdv_origin?.id?.toString()
			}
		]
	})

	const pdvsOptions = useMemo(() => {
		if (!dataAuth?.origin_scope?.pdv_origin.id) return []
		const elementRemove: { label: any; value: any } = formData.pdv_id[0]
		let options_list = [
			{
				label: 'Todos',
				value: ''
			}
		]
		dataAuth?.user?.pdvs?.forEach((element: { name: any; id: any }) => {
			if (elementRemove?.value != element.id) {
				options_list.push({ label: element.name, value: element.id })
			}
		})
		return options_list
	}, [formData.pdv_id])

	const handleFieldChange = (name: string, e: any): void => {
		if (name == 'period' && e.target.value == 'custom') {
			setCustomSelect(true)
		}
		if (name == 'period' && e.target.value != 'custom') {
			setCustomSelect(false)
		}
		setFormData({
			...formData,
			[name]: e.target.value
		})
	}
	const handleSelectsChanges = (name: string, e: any): void => {
		setFormData({
			...formData,
			[name]: e
		})
	}

	const onSubmit = async () => {
		setLoading(true)
		try {
			if (formData.pdv_id?.length == 0) {
				setLoading(false)
				return showAlert({
					title: 'Ops...!',
					text: 'Selecione pelo menos um PDV',
					type: 'info'
				})
			}
			if(formData.period == 'total') setChangeLabel(true)
			else setChangeLabel(false)
			let allPdv: any = []
			if (
				formData.pdv_id.find((elemnt: { value: string }) => elemnt.value == '')
			) {
				dataAuth?.user?.pdvs?.map((pdv: any) =>
					allPdv.push({
						label: pdv.name,
						value: pdv.id
					})
				)
			}
			let response = await searchGeneralDashboard({
				period: formData.period,
				final_date: CustomSelect ? formData.final_date : todayOnlyDate,
				initial_date: CustomSelect ? formData.initial_date : todayOnlyDate,
				pdv_id:
					allPdv.length > 0
						? allPdv
								.filter((item: any) => item?.value != '')
								?.map((item: any) => item?.value)
								.join(',')
						: formData.pdv_id
								?.filter((item: any) => item?.value != '')
								?.map((item: any) => item?.value)
								.join(',')
			})
			if (response) setLoading(false) //stop loading
			setDashboardControl(response)
			return
		} catch (e: any) {
			setLoading(false)
			console.log(e)
			if (e?.response?.data?.error_code == 'date_error') {
				showAlert({
					title: 'Ops...!',
					text: e?.response?.data?.error_message,
					type: 'error'
				})
			}
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			if (dataAuth?.origin_scope?.pdv_origin?.id) {
				setFormData({
					...formData,
					['pdv_id']: [
						{
							label: dataAuth?.origin_scope?.pdv_origin?.name,
							value: dataAuth?.origin_scope?.pdv_origin?.id?.toString()
						}
					]
				})

				const response = await searchGeneralDashboard({
					period: formData.period,
					final_date: CustomSelect ? formData.final_date : todayOnlyDate,
					initial_date: CustomSelect ? formData.initial_date : todayOnlyDate,
					pdv_id: dataAuth?.origin_scope?.pdv_origin?.id
				})
				if (response) {
					setDashboardControl(response)
				}
			}
		}
		fetchData()
	}, [dataAuth])
	return (
		<>
			{dashboardControl ? (
				<>
					<Row>
						<Col md={12} xs={12}>
							<Card>
								<Card.Body>
									<Row className='mb-2'>
										<Col sm={CustomSelect ? 3 : 6}>
											<Form.Group>
												<Form.Label>PDV</Form.Label>

												<Select
													name='pdv_id'
													onChange={(e: any) => {
														handleSelectsChanges('pdv_id', e)
													}}
													placeholder='Selecione...'
													isMulti
													options={pdvsOptions}
													value={formData.pdv_id}
													noOptionsMessage={() => 'Sem Resultados.'}
													styles={customStyles}
												/>
												{/* <Form.Select
                                                    name='pdv_id'
                                                    onChange={e => {
                                                        handleFieldChange('pdv_id', e)
                                                    }}
                                                    value={formData.pdv_id}

                                                >

                                                    {dataAuth?.user?.pdvs?.map((item: any, index: number) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item?.name}
                                                        </option>
                                                    ))}
                                                </Form.Select> */}
											</Form.Group>
										</Col>
										<Col sm={CustomSelect ? 2 : 5}>
											<Form.Group>
												<Form.Label>Período</Form.Label>
												<Form.Select
													name='period'
													value={formData.period}
													onChange={e => {
														handleFieldChange('period', e)
													}}
												>
													<option value={'hoje'}>Hoje</option>
													<option value={'ontem'}>Ontem</option>
													<option value={'mes'}>Este Mês</option>
													<option value={'total'}>Total em Tempo Real</option>
													<option value={'custom'}>Customizado</option>
												</Form.Select>
											</Form.Group>
										</Col>
										{CustomSelect && (
											<>
												<Col sm='3'>
													<Form.Group>
														<Form.Label>Data Inicial</Form.Label>
														<Form.Control
															name='initial_date'
															onChange={e => {
																handleFieldChange('initial_date', e)
															}}
															type='date'
															value={formData.initial_date}
														/>
													</Form.Group>
												</Col>
												<Col sm='3'>
													<Form.Group>
														<Form.Label>Data Final</Form.Label>
														<Form.Control
															name='final_date'
															onChange={e => {
																handleFieldChange('final_date', e)
															}}
															type='date'
															value={formData.final_date}
														/>
													</Form.Group>
												</Col>
											</>
										)}
										<Col sm={1} style={{ marginTop: '30px' }}>
											<Button
												variant='primary'
												onClick={onSubmit}
												style={{ width: '100%' }}
												disabled={loading}
											>
												<BiRefresh className='p-0 w-100' size={25} />
											</Button>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					{!loading ? (
						<>
							<Row className='g-3 mb-3 mt-3'>
								<CardsInfo
									contract_pf={dashboardControl.newContract.contract_pf}
									contract_pj={dashboardControl.newContract.contract_pj}
									contract_sva={dashboardControl.newContract.contract_sva}
								></CardsInfo>
							</Row>
							<Row className='g-3 mb-3 mt-3'>
								<ChartDashboard
									holder={dashboardControl.subscription.holder}
									dependent={dashboardControl.subscription.dependent}
									total={dashboardControl.subscription.total}
									changeLabel={changeLabel}
								></ChartDashboard>
								<TableDashboard data={dashboardControl.plans}></TableDashboard>
							</Row>
							<Row className='g-3 mb-3 mt-3'>
								<ProgressBarDashboard
									contract_created={parseInt(dashboardControl.contract.contract_created)}
									contract_signed={parseInt(dashboardControl.contract.contract_signed)}
									contract_canceled={parseInt(
										dashboardControl.contract.contract_canceled
									)}
								></ProgressBarDashboard>
							</Row>
						</>
					) : (
						<CustomLoading />
					)}
				</>
			) : (
				''
			)}
		</>
	)
}

export default Dashboard
