

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import CountUp from 'react-countup';
import { BsFillFileEarmarkTextFill } from 'react-icons/bs';
import { FaBuilding } from 'react-icons/fa';

import { ICardData } from '../inteface';

const CardsInfo: React.FC<ICardData> = ({ contract_pf, contract_pj, contract_sva }) => {
    return (

        <>
            {/* <Col >
                <Card >
                    <Card.Body >
                        <Col className="text-center">
                            <div className='text-primary' style={{
                                width: '4rem',
                                height: '4rem',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: '2px',
                                borderStyle: 'solid',
                                margin: '0 auto 1rem auto',
                            }} >
                                <FontAwesomeIcon
                                    icon="user-plus"
                                    className="fs-2 text-primary"
                                />
                            </div>
                            <h4 className="mb-1 font-sans-serif">
                                <CountUp
                                    start={0}
                                    end={newBusinessPartner}
                                    duration={2.75}
                                    className="text-bold"
                                    separator="."
                                />
                                <span className="fw-normal text-bold">{' ' + 'Novos Clientes'}</span>
                            </h4>

                        </Col>
                    </Card.Body>
                </Card>
            </Col> */}
            <Col >
                <Card>
                    <Card.Body>
                        <Col className="text-center">
                            <div className='text-info' style={{
                                width: '4rem',
                                height: '4rem',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: '2px',
                                borderStyle: 'solid',
                                margin: '0 auto 1rem auto',
                                //color: 'blue'
                            }} >
                                <FontAwesomeIcon
                                    icon="users"
                                    className="fs-2 text-info"
                                />
                            </div>
                            <h4 className="mb-1 font-sans-serif">
                                <CountUp
                                    start={0}
                                    end={contract_pf}
                                    duration={2.75}
                                    className="text-bold"
                                    separator="."
                                />
                                <span className="fw-normal text-bold">{' ' + 'Contratos PF Assinados'}</span>
                            </h4>

                        </Col>
                    </Card.Body>
                </Card>
            </Col>
            <Col >
                <Card >
                    <Card.Body>
                        <Col className="text-center">
                            <div style={{
                                width: '4rem',
                                height: '4rem',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: '2px',
                                borderStyle: 'solid',
                                margin: '0 auto 1rem auto',
                                color: '#69b355'
                            }} >
                                <FaBuilding
                                    size={32}
                                //className="fs-2"
                                />
                            </div>
                            <h4 className="mb-1 font-sans-serif">
                                <CountUp
                                    start={0}
                                    end={contract_pj}
                                    duration={2.75}
                                    className="text-bold"
                                    separator="."
                                />
                                <span className="fw-normal text-bold">{' ' + 'Contratos PJ Assinados'}</span>
                            </h4>

                        </Col>
                    </Card.Body>
                </Card>
            </Col>
            <Col >
                <Card >
                    <Card.Body>
                        <Col className="text-center">
                            <div className='text-warning' style={{
                                width: '4rem',
                                height: '4rem',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: '2px',
                                borderStyle: 'solid',
                                margin: '0 auto 1rem auto',
                                //color: 'blue'
                            }} >
                                <BsFillFileEarmarkTextFill
                                    size={32}
                                //className="fs-2 text-warning"
                                />
                            </div>
                            <h4 className="mb-1 font-sans-serif">
                                <CountUp
                                    start={0}
                                    end={contract_sva}
                                    duration={2.75}
                                    className="text-bold"
                                    separator="."
                                />
                                <span className="fw-normal text-bold">{' ' + 'Contratos SVA Assinados'}</span>
                            </h4>


                        </Col>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
};

export default CardsInfo;