

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import { FC } from 'react';
import { Row, Col, Card, ProgressBar } from 'react-bootstrap';
import CountUp from 'react-countup';
import { BsFillFileEarmarkTextFill } from 'react-icons/bs';
import { FaBuilding } from 'react-icons/fa';


interface IContractData {
    contract_created: number,
    contract_signed: number,
    contract_canceled: number,
    contract_defaulter?: number,

}

const ProgressBarDashboard: React.FC<IContractData> = (data) => {
    let progessData: any[] = []
    
    if (data.contract_created > 0) progessData.push(
        {
            id: 1,
            title: 'Criados',
            amount: data.contract_created,
            variant: 'progress-gradient'
        }
    )
    if (data.contract_signed > 0) progessData.push(
        {
            id: 2,
            title: 'Assinados',
            amount: data.contract_signed,
            variant: 'success'
        }
    )
   
    if (data.contract_canceled > 0) progessData.push(
        {
            id: 3,
            title: 'Cancelados',
            amount: data.contract_canceled,
            variant: 'danger'
        }
    )
   


    const total = progessData.length>0? progessData.reduce((acc, data) => acc + data.amount, 0): 0;
    return (

        <>
            <Col md={12} xs={12}>
                <Card>
                    <FalconCardHeader
                        title={`Contratos`}
                        titleTag="h5"
                    />
                    <Card.Body as={Flex} alignItems="center">
                        <div className="w-100">
                            {total > 0 ?
                                <>
                                    <ProgressBar
                                        className="font-sans-serif fs--1 fw-medium mt-xxl-auto rounded-4 overflow-visible"
                                        style={{ height: 20 }}
                                    >
                                        {progessData.map((item, index) => (
                                            <ProgressBar
                                                variant={item.variant}
                                                now={(item.amount / total) * 100}
                                                key={item.id}
                                                className={classNames('overflow-visible position-relative', {
                                                    'rounded-end rounded-pill': index === 0,
                                                    'rounded-start rounded-pill':
                                                        index === progessData.length - 1,
                                                    'border-end border-white border-2':
                                                        index !== progessData.length - 1,
                                                    'rounded-0':
                                                        index !== progessData.length - 1 && index !== 0
                                                })}
                                                label={
                                                    <span
                                                        className="text-700 position-absolute"
                                                        style={{ top: -30 }}
                                                    >
                                                        {item.amount.toLocaleString()}
                                                    </span>
                                                }
                                            />
                                        ))}
                                    </ProgressBar>
                                    <Row className="fs--1 fw-semi-bold text-500 mt-3 g-0">
                                        {progessData.map((item, index) => (
                                            <Col
                                                xs="auto"
                                                key={index}
                                                as={Flex}
                                                alignItems="center"
                                                className="pe-3"
                                                style={{ justifyContent: 'space-between' }}
                                            >
                                                <span className={`dot bg-${item.variant}`}></span>

                                                <span style={{ marginRight: 2 }}>{item.title}</span>
                                                <span>
                                                    ({((item.amount / total) * 100).toFixed(1)}%)
                                                </span>
                                            </Col>
                                        ))}
                                    </Row>
                                </>
                                : ''}

                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
};

export default ProgressBarDashboard;