
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FC } from 'react';
import { Row, Col, Card, Table } from 'react-bootstrap';

import SimpleBarReact from 'simplebar-react';
import { IPlansData } from '../inteface';


interface Idata {
    data:IPlansData[]
}
const TableDashboard: React.FC<Idata> = (data) => {
    return (
        <>
            <Col md={6} xs={6}>
                <Card style={{ height: 500 }}>
                    <FalconCardHeader
                        title="Planos Vendidos"
                        titleTag="h5"
                    />
                    <Card.Body className='pt-0 pb-0' >
                        <SimpleBarReact className="ask-analytics">
                            <Table className="fs--1 mb-0">
                                <thead className="bg-200 text-800">
                                    <tr>
                                        <th className="text-nowrap ">Planos</th>
                                        <th className="text-nowrap text-center">Quantidade</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.data.length > 0 ? data.data.map((item, index) => (
                                        <tr key={index}>
                                            <td className='border-bottom-0'>
                                                {item.membership_tier_name}
                                            </td>
                                            <td
                                                className='border-bottom-0 text-center'
                                            >
                                                {item.quantity}
                                            </td>
                                        </tr>
                                    )) : null}

                                </tbody>
                            </Table>
                        </SimpleBarReact>
                    </Card.Body>

                </Card>
            </Col>
        </>
    )
}

export default TableDashboard